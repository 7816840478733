var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _vm._l(_vm.activeCopuns, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "active-discount-" + index,
                staticClass: "coupon-col",
                attrs: { cols: "12", sm: "6", lg: "4" }
              },
              [
                _c("CouponCard", {
                  attrs: { coupon: discount, type: "activeDiscount" },
                  on: {
                    showDetails: _vm.showActiveCoupon,
                    reload: _vm.getActiveCopuns
                  }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.vouchers, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "voucher-" + index,
                attrs: { cols: "12", sm: "6", lg: "4" }
              },
              [
                _c("CouponCard", {
                  attrs: { coupon: discount, type: "voucher" },
                  on: { showDetails: _vm.showVoucher, reload: _vm.loadVouchers }
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-row",
        [
          _vm._l(_vm.galleryCoupons, function(coupon, index) {
            return _c(
              "v-col",
              {
                key: "coupon-" + index,
                attrs: { cols: "12", sm: "6", lg: "4" }
              },
              [
                _c("ProductCard", {
                  attrs: {
                    product: coupon,
                    type: coupon.metaData.voucher_info.selected
                      ? "selected"
                      : "available"
                  },
                  on: {
                    openDetails: _vm.openDetails,
                    reload: _vm.getSelectedCoupons
                  }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.activeCashbackCoupons, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "active-cashback-discount-" + index,
                staticClass: "coupon-col",
                attrs: { cols: "12", sm: "6", lg: "4" }
              },
              [
                _c("WelcomeCouponCard", {
                  staticClass: "welcome-style",
                  attrs: {
                    coupon: discount,
                    type: "activeCashbackDiscount",
                    customCssClass: "welcome-style"
                  },
                  on: {
                    showDetails: _vm.showActiveCoupon,
                    reload: _vm.getActiveCashbackCoupons
                  }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.activeWelcomeCoupons, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "active-welcome-discount-" + index,
                staticClass: "coupon-col",
                attrs: { cols: "12", sm: "6", lg: "4" }
              },
              [
                _c("CouponCard", {
                  attrs: {
                    coupon: discount,
                    title: "coupons.discounts.welcome",
                    type: "welcomeCoupon"
                  },
                  on: {
                    showDetails: _vm.showActiveCoupon,
                    reload: _vm.getActiveCopuns
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }